<template>
  <div class="content"  v-loading="loadingStatus.getTable">
    <div>
      <el-form ref="staffRef" :inline="true" size="medium" :model="dataForm">

        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="所属工会：">
            <el-select style="width: 100%;" v-model="dataForm.presidentId" placeholder="请选择" clearable>
              <el-option v-for="(item ) in presidentList" :key="item.id"
                         :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

        </el-col>
        <el-button type="primary" @click="getTable" size="medium">查询</el-button>
        <el-button  size="medium" @click="getReset" plain>重置</el-button>
        <el-button type="primary" size="medium" @click="getAdd">添加</el-button>
      </el-form>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          :stripe="true"
          style="width: 100%">
        <el-table-column prop="date" label="编号" align="center" width="80">
          <template slot-scope="scope">
            <span>{{
                scope.$index + 1 + (page.pageNo - 1) * page.pageSize
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="presidentName" label="工会名称" align="center" width="180">
        </el-table-column>
        <el-table-column prop="gameName" label="游戏名称" align="center">
        </el-table-column>
        <el-table-column prop="allocationRatio" label="分配比例" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.allocationRatio * 100}}%</span>
          </template>
        </el-table-column>
        <el-table-column width="140px" prop="cycleClearing" label="结算周期(天)" align="center">
        </el-table-column>
<!--        <el-table-column prop="cleaningAccount" label="结算账号" align="center">
        </el-table-column>
        <el-table-column prop="cleaningWay" label="结算方式" align="center">
        </el-table-column>-->
        <el-table-column prop="remark" label="备注" align="center">
        </el-table-column>
        <el-table-column prop="createTime" width="160px" label="创建时间" align="center">
        </el-table-column>

        <el-table-column prop="address" align="center" label="操作">
          <template slot-scope="scope">
            <span style="color: #1D90FF;cursor: pointer;" @click="getEdit(scope.row)">修改</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          class="page"
          background
          @size-change="handleSizeChange"
          @current-change="currentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
          :current-page="page.pageNo"
      >
      </el-pagination>
    </div>

    <el-dialog
        :title="objTitle[titleShow]"
        width="40%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @close="getCancel"
        :visible.sync="dialogVisible">
      <el-form ref="ruleRef" :rules="rules" size="medium" :model="formData" label-width="100px">
        <el-form-item label="工会：" prop="presidentId">
          <el-select class="select-width" v-model="formData.presidentId">
            <el-option v-for="(item) in presidentList" :key="item.id"
                       :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="游戏：" prop="gameId">
          <el-select class="select-width" v-model="formData.gameId">
            <el-option v-for="(item ) in gamesList" :key="item.id"
                       :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分配比例：" prop="allocationRatio">
<!--          支持小数点后两位-->
          <el-input v-model="formData.allocationRatio"></el-input>
        </el-form-item>
        <el-form-item label="结算周期：" prop="cycleClearing">
          <el-input v-model="formData.cycleClearing"></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input row="2" v-model="formData.remark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="getCancel">取 消</el-button>
        <el-button size="medium" v-loading="loadingStatus.submitForm" @click="submitForm()" type="primary">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  commissionRulesAdd,
  commissionRulesList,
  commonInfo,
} from "../../service/moudules/promotionLinkApi";
import loadingStatusMixin from "@/mixins/loadingStatus";

export default {
  name: "commissionRule",
  mixins: [loadingStatusMixin],
  data (){
    return {
      dataForm: {
        presidentName: '', //游戏ID
        presidentId: '', //公会ID
      },
      tableData: [],
      formData: {
        commissionRulesId: '', //佣金规则ID
        presidentId: '', //会长ID
        gameId: '', //游戏ID
        allocationRatio: '', //分配比例
        cycleClearing: '', //结算周期
        remark: '',
      },
      rules: {
        presidentId: [{required: true, message: '请选择会长', trigger: 'blur'},],
        gameId:[{required: true, message: '请选择游戏', trigger: 'blur'}],
        allocationRatio:[{required: true, trigger: 'blur',
            validator: (rule, value, callback)=> {
          const reg = /^\d+.?\d{0,2}$/;
          if(!value) {
            callback(new Error('请输入分配比例'))
          } else if(!Number(value)) {
            callback(new Error('请输入数字值'))
          } else {
            if (reg.test(value)) {
              callback();
            } else {
              callback(new Error('小数点后最多只能输入两位'))
            }
          }
        }
        }],
        cycleClearing:[{required: true, message: '请输入结算周期', trigger: 'blur'}]
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      objTitle: {
        add: '添加佣金规则',
        edit: '修改佣金规则',
      },
      gamesList: [], //游戏
      presidentList: [], //会长
      titleShow: 'add',
      dialogVisible: false
    }
  },
  mounted() {
    this.getTable();
    this.getCommonInfo();
  },
  methods:{
    async getCommonInfo() {
      let res = await commonInfo();
      if (res.code == 200) {
        this.presidentList = res.data.presidentList;
        this.gamesList = res.data.gamesList;
      }
    },
    async getTable() {
      if ( this.hasLoadingState('getTable') ) return;
      try {
        this.addLoadingState('getTable');
        let params = {
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize
        };
        const searchFormValue = this.dataForm;
        Object.keys(searchFormValue).map((key) => {
          searchFormValue[key] && (params[key] = searchFormValue[key]);
        });
        let res = await commissionRulesList(params);
        this.removeLoadingState("getTable");
        console.log("注册明细：",res);
        if ( res.code == 200) {
          this.tableData = res.data.records;
          this.page.pageNo = res.data.current;
          this.page.total = res.data.total;
        } else {
          this.removeLoadingState("getTable");
          this.$message.error(`${res.msg}！`);
        }
      } catch (e) {
        this.removeLoadingState("getTable");
      }
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getTable();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getTable();
    },
    getReset() {
      this.dataForm = {
        presidentName: '',
      };
      this.getTable();
    },
    submitForm(){
      this.$refs.ruleRef.validate(async (valid) => {
        if (valid) {
          if ( this.hasLoadingState('submitForm') ) return;
          try {
            this.addLoadingState('submitForm');
            let params = {
              ...this.formData
            }
            if(this.titleShow == 'add') {
              delete params.commissionRulesId;
            }
            if(this.titleShow == 'edit') {
              //游戏
              this.gamesList.map(( item )=> {
                if(item.name == params.gameId) {
                  params.gameId = item.id;
                }
              });
              this.presidentList.map(( item ) => {
                if(item.name == params.presidentId) {
                  params.presidentId = item.id;
                }
              })
            }
            let res = await commissionRulesAdd(params);
            this.removeLoadingState("submitForm");
            if(res.code == 200 ){
              this.dialogVisible = false;
              this.$message({
                message: res.msg,
                type: 'success'
              });
              this.getTable();
            } else {
              this.dialogVisible = false;
              this.$message.error(res.msg);
            }
          }catch (e) {
            console.log(e);
            this.removeLoadingState("submitForm");
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //添加
    getAdd() {
      this.titleShow = 'add';
      this.dialogVisible = true;
    },
    getEdit(row) {
      this.dialogVisible = true;
      this.titleShow = 'edit';
      console.log("row:",row);
      let data = {...row};
      //游戏
      this.gamesList.map(( item )=> {
        if(item.id == data.gameId) {
          data.gameId = item.name;
        }
      });
      this.presidentList.map(( item ) => {
        if(item.id == data.presidentId) {
          data.presidentId = item.name;
        }
      })
      this.formData = {
        commissionRulesId: data.commissionRulesId, //佣金规则ID
        presidentId: data.presidentId, //会长ID
        gameId: data.gameId, //游戏ID
        allocationRatio: data.allocationRatio, //分配比例
        cycleClearing: data.cycleClearing, //结算周期
        remark: data.remark,
      }
      // this.formData = {...data};
    },
    getCancel() {
      this.dialogVisible = false;
      this.$refs.ruleRef.resetFields();
      this.formData = {
        commissionRulesId: '', //佣金规则ID
        presidentId: '', //会长ID
        gameId: '', //游戏ID
        allocationRatio: '', //分配比例
        cycleClearing: '', //结算周期
        remark: '',
      }
    }
  },

}
</script>

<style scoped lang="less">
.content{
  padding: 20px;
  margin: 10px;
  border: 1px solid #dcdfe6;
  text-align: initial;
  background-color: white;
  .page {
    text-align: right;
    margin-top: 15px;
  }
  .select-width{
    width: 100%;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar{
    width: 1px;
    height: 10px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: rgba(0,0,0,.2);
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: rgba(0,0,0,.1);
  }
  ::v-deep .el-table__header-wrapper .el-table__cell{
    height:50px;
    background-color:#fafafa;
    color:#333333;
    padding:0
  }
}

</style>